<template>
  <div>
    <div class="locale">
      <h2><a-button @click="$router.back()" shape="circle" icon="arrow-left"/>答卷详情</h2>
      <ol>
        <li><router-link to="/">后台</router-link></li>
        <li><a href="javascript:;">测验</a></li>
        <li><a href="javascript:;">答卷</a></li>
        <li>详情</li>
      </ol>
    </div>

    <div class="box">
<!--      <div class="box-head">-->
<!--        <h3>登录详情</h3>-->
<!--      </div>-->
      <div class="box-body">
        <table class="view">
          <tr>
            <th>编号：</th>
            <td>{{item.id}}</td>
            <th>状态：</th>
            <td>
              <label class="label" :class="[item.state.value]">{{item.state.label}}</label>
            </td>
          </tr>
          <tr>
            <th>所属用户：</th>
            <td>{{item.user.nickname}}</td>
            <th>所属角色：</th>
            <td>{{item.role.name}}</td>
          </tr>
          <tr>
            <th>答题量：</th>
            <td>{{item.count.answers}}</td>
            <th>得分：</th>
            <td>{{item.score}}</td>
          </tr>
          <tr>
            <th>开始时间：</th>
            <td>{{item.created.format('yyyy-MM-dd hh:mm:ss')}}</td>
            <th>完成时间：</th>
            <td>
              <span v-if="item.finished">{{item.finished.format('yyyy-MM-dd hh:mm:ss')}}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="box">
     <div class="box-head">
       <h3>答题列表</h3>
     </div>
      <div class="box-body">
          <a-empty v-if="answers.length == 0" />
          <table class="list" v-if="answers.length > 0">
          <tr>
            <!-- <th style="width: 120px;">#</th> -->
            <th style="width: 120px;">排序</th>
            <th>问题</th>
            <th>回答</th>
            <th style="width: 120px;">单题分值</th>
            <th style="width: 120px;">答题次数</th>
            <th style="width: 120px;">得分</th>
            <th style="width: 120px;">状态</th>
          </tr>
          <tr v-for="item in answers">
            <!-- <td>{{item.id}}</td> -->
            <td>
              <div>{{item.index}}</div>
            </td>
            <td>
              <div>{{item.question}}</div>
            </td>
            <td>
              <div>{{item.answer}}</div>
            </td>
            <td>
              <div>{{item.value}}</div>
            </td>
            <td>
              <div>{{item.times}}</div>
            </td>
            <td>
              <div>{{item.score}}</div>
            </td>
            <td>
              <label class="label" :class="[item.state.value]">{{item.state.label}}</label>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <!-- <div class="box">
      <div class="box-head">
        <h3>详细信息</h3>
      </div>
      <div class="box-body">
      <table class="view">
          <tr>
            <th>所属用户：</th>
            <td>{{item.user.nickname}}</td>
            <th>所属角色：</th>
            <td>{{item.role.name}}</td>
          </tr>
          <tr>
            <th>答题量：</th>
            <td>{{item.count.answers}}</td>
            <th>得分：</th>
            <td>{{item.score}}</td>
          </tr>
          <tr>
            <th>单题分值：</th>
            <td>{{item.count.answers}}</td>
            <th>完成时间：</th>
            <td>
              <span v-if="item.finished">{{item.finished.format('yyyy-MM-dd hh:mm:ss')}}</span>
            </td>
          </tr>
        <tr>
          <th>备注：</th>
          <td colspan="3">{{item.remark}}</td>
        </tr>
        <tr>
          <th>修改时间：</th>
          <td>{{item.changed.format('yyyy-MM-dd hh:mm:ss')}}</td>
          <th>操作时间：</th>
          <td>{{item.created.format('yyyy-MM-dd hh:mm:ss')}}</td>
        </tr>
      </table>
      </div>
    </div> -->

  </div>
</template>


<script>
export default {
  name: 'SysOplogDetail',
  data() {
    return {
      item: {
        staff: {}, records: [],
        changed: new Date(), created: new Date()
      },
      answers: []
    }
  },
  mounted() {
    this.request();
  },

  methods: {
    request() {
      let id = this.$route.params.id;
      this.$get('/test/paper/detail/' + id).then(res => {
        let ajax = res.data;
        if (ajax.code == 0 && ajax.data) {
          this.$util.timeFields(ajax.data.item);
          this.item = ajax.data.item;
          this.answers = ajax.data.answers;
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
</style>